import React from 'react'
import Helmet from 'react-helmet'
import Pricing from '../Pricing'
import PropTypes from 'prop-types'

const PricingPageTemplate = ({
  title,
  meta_title,
  meta_description,
  pricing,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='section  edge--top--reverse is-light-primary'>
      <div className='container'>
        <div className='content'>
          <h2 className='has-text-weight-semibold is-size-2'>
            {pricing.heading}
          </h2>
          <p className='is-size-5'>{pricing.description}</p>
          <Pricing data={pricing.plans} />
        </div>
      </div>
    </section>
  </div>
)

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array
  }),
}

export default PricingPageTemplate
