import React from 'react'
import PropTypes from 'prop-types'

const Pricing = ({ data }) => (
  <div>
    <div className='columns'>
      <div className={`column`}>
        <div className='pricing-table'>
          {data.map(price => (
            <div key={price.plan} className={`pricing-plan ${price.active}`}>
              <div className='plan-header'>{price.plan}</div>
              <div className='plan-price'><span className='plan-price-amount'><span
                className='plan-price-currency'>$</span>{price.price}</span>/month
              </div>
              <div className='plan-items'>
                {price.items.map(item => (
                  <div className='plan-item'>{item}</div>
                ))}
              </div>
              <div className='plan-footer'>
                <button className='button is-fullwidth' >Choose</button>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>

    <div className='pricing-table is-comparative'>
      <div className='pricing-plan is-features'>
        <div className='plan-header'>Features</div>
        <div className='plan-price'><span
          className='plan-price-amount'>&nbsp;</span></div>
        <div className='plan-items'>
          <div className='plan-item'>Storage</div>
          <div className='plan-item'>Domains</div>
          <div className='plan-item'>Bandwidth</div>
          <div className='plan-item'>Email Boxes</div>
        </div>
        <div className='plan-footer' />
      </div>
      <div className='pricing-plan'>
        <div className='plan-header'>Starter</div>
        <div className='plan-price'><span className='plan-price-amount'><span
          className='plan-price-currency'>$</span>20</span>/month
        </div>
        <div className='plan-items'>
          <div className='plan-item' data-feature='Storage'>20GB</div>
          <div className='plan-item' data-feature='Domains'>100</div>
          <div className='plan-item' data-feature='Bandwidth'>-</div>
          <div className='plan-item' data-feature='Email Boxes'>-</div>
        </div>
        <div className='plan-footer'>
          <button className='button is-fullwidth' disabled='disabled'>Current
            plan
          </button>
        </div>
      </div>

      <div className='pricing-plan is-warning'>
        <div className='plan-header'>Startups</div>
        <div className='plan-price'><span className='plan-price-amount'><span
          className='plan-price-currency'>$</span>40</span>/month
        </div>
        <div className='plan-items'>
          <div className='plan-item' data-feature='Storage'>20GB</div>
          <div className='plan-item' data-feature='Domains'>25</div>
          <div className='plan-item' data-feature='Bandwidth'>1TB</div>
          <div className='plan-item' data-feature='Email Boxes'>-</div>
        </div>
        <div className='plan-footer'>
          <button className='button is-fullwidth'>Choose</button>
        </div>
      </div>

      <div className='pricing-plan is-active'>
        <div className='plan-header'>Growing Team</div>
        <div className='plan-price'><span className='plan-price-amount'><span
          className='plan-price-currency'>$</span>60</span>/month
        </div>
        <div className='plan-items'>
          <div className='plan-item' data-feature='Storage'>200GB</div>
          <div className='plan-item' data-feature='Domains'>50</div>
          <div className='plan-item' data-feature='Bandwidth'>1TB</div>
          <div className='plan-item' data-feature='Email Boxes'>100</div>
        </div>
        <div className='plan-footer'>
          <button className='button is-fullwidth'>Choose</button>
        </div>
      </div>

      <div className='pricing-plan is-danger'>
        <div className='plan-header'>Enterprise</div>
        <div className='plan-price'><span className='plan-price-amount'><span
          className='plan-price-currency'>$</span>100</span>/month
        </div>
        <div className='plan-items'>
          <div className='plan-item' data-feature='Storage'>2TB</div>
          <div className='plan-item' data-feature='Domains'>100</div>
          <div className='plan-item' data-feature='Bandwidth'>1TB</div>
          <div className='plan-item' data-feature='Email Boxes'>1000</div>
        </div>
        <div className='plan-footer'>
          <button className='button is-fullwidth'>Choose</button>
        </div>
      </div>
    </div>

  </div>
)

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      active: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default Pricing
